import {lazy} from 'react';
import AppVersionUtil from "./AppVersionUtil";

export const chunk = (lazyComponent : () => Promise<any> | undefined) => {
  return lazy(() => {
    AppVersionUtil.verify();
    const component = lazyComponent();

    if (!component) {
      window.location.reload();
      return Promise.resolve();
    }

    return component.catch(() => {
      return;
    });
  });
};