export enum StorageKey {
  jwt = 'jwt',
  currentSiteId = 'currentSiteId',
  currentAccountId = 'currentAccountId'
}

type StoreSuccess = boolean;

const canLocalStorageBeUsed = (): boolean => {
  try {
    if (window.localStorage) {
      window.localStorage.setItem('test', 'test')
      const result: string | null = window.localStorage.getItem('test');
      window.localStorage.removeItem('test');
      return result === 'test';
    }
  } catch (e) {
  }
  return false;
}

// If Clive is embedded in an iframe, localStorage won't work. Use a fail-safe approach so that accessing localStorage does not prevent the rest of the functionality from working.
if (!canLocalStorageBeUsed()) {
  Object.defineProperty(window, 'localStorage', {
    value: {
      setItem: (): void => {
      },
      removeItem: (): void => {
      },
      clear: (): void => {
      },
      key: (): void => {
      },
      getItem: (): null => null,
      length: 0,
      disabled: true
    }
  });
}

export default class LocalStorageUtil {
  private static isLocalStorageAvailable(): boolean {
    return window.localStorage && window.localStorage.disabled !== true;
  }

  public static store(key: StorageKey, value: string | number): StoreSuccess {
    try {
      if (this.isLocalStorageAvailable()) {
        window.localStorage.setItem(key, value + '');
        return true;
      }
    } catch (e) {
    }
    return false
  }

  public static get(key: StorageKey): string | null {
    try {
      if (this.isLocalStorageAvailable()) {
        return localStorage.getItem(key);
      }
    } catch (e) {
    }
    return null;
  }

  public static delete(key: StorageKey): StoreSuccess {
    try {
      if (this.isLocalStorageAvailable()) {
        localStorage.removeItem(key);
        return true;
      }
    } catch (e) {
    }

    return false;
  }
}